<template>
    <div class="p-server-add" v-loading="!ready">
        <el-form label-width="150px" :model="form" :rules="rules" ref="form">
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="服务器名称，如 usa1"
                    maxlength="16"
                ></el-input>
            </el-form-item>
            <el-form-item label="IP" prop="ip">
                <el-input
                    v-model="form.ip"
                    placeholder="服务器IP"
                    maxlength="15"
                ></el-input>
            </el-form-item>
            <!-- <el-form-item label="SSH root 密码">
				<el-input></el-input>
			</el-form-item>
			<el-form-item label="Mysql root 密码">
				<el-input></el-input>
			</el-form-item> -->
            <el-form-item label="服务商" prop="provider">
                <el-select v-model="form.provider">
                    <el-option value="linode">Linode</el-option>
                    <el-option value="aliyun">阿里云</el-option>
                    <el-option value="google">谷歌</el-option>
                    <el-option value="aws">亚马逊</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-button type="primary">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<style lang="less"></style>

<script>
export default {
    components: {},
    data() {
        return {
            ready: false,
            isLoading: false,
            form: {
                name: "",
                ip: "",
                provider: "",
            },
            rules: {
                name: [{ required: true, message: "请填写服务器名称" }],
                ip: [{ required: true, message: "请填写服务器IP" }],
                provider: [{ required: true, message: "请选择服务商" }],
            },
        };
    },
    computed: {},
    mounted() {
        this.ready = true;
    },
    methods: {},
    filters: {},
};
</script>
